.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loading-animation {
    position: relative;
}

.loading-animation::before {
    content: " ";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 4px solid #f39c12;
    border-radius: 12px;
    animation: border-tracing 2s linear infinite;
}

@keyframes border-tracing {
    0% {
        transform: scaleX(0);
    }

    50% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

.loading-text {
    font-size: 100px;
    font-weight: bold;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(to right, #f39c12, #3498db);
}