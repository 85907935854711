/* Montserrat */

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  letter-spacing: 0.15px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Roboto */

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../public/assets/fonts/Roboto/Roboto-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../public/assets/fonts/Roboto/Roboto-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
