



.container{
 width: 100%;
  margin: auto;
  position: relative;
 
}
.hamburger.is-active{
  position: absolute;
  right: 10px;
  top: -19px;
}



.hamburger .line{
  width: 30px;
  height: 4px;
  background-color:#178E80;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

/* ONE */

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(14px) rotate(45deg);
  -ms-transform: translateY(14px) rotate(45deg);
  -o-transform: translateY(14px) rotate(45deg);
  transform: translateY(14px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}



